.App {
    text-align: center;
    padding: 20px;
  }
  
  nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  nav ul li {
    display: inline;
    margin: 0 10px;
  }
  
  nav ul li a {
    text-decoration: none;
    color: #333;
  }