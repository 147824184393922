:root {
    --primary-color: #6366f1;
    --secondary-color: #4ade80;
    --text-color: #1f2937;
    --bg-color: #f3f4f6;
    --card-bg: #ffffff;
    --error-color: #ef4444;
    --success-color: #10b981;
    --input-border: #d1d5db;
    --input-focus: #a5b4fc;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    background-color: var(--bg-color);
    color: var(--text-color);
  }
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
  }
  
  .content {
    width: 100%;
    max-width: 450px;
    padding: 2.5rem;
    background-color: var(--card-bg);
    border-radius: 1rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .header {
    text-align: center;
    margin-bottom: 2.5rem;
  }
  
  .logo {
    width: 150px;
    height: auto;
    margin-bottom: 1.5rem;
  }
  
  .header h1 {
    font-size: 2.25rem;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
  }
  
  .deleteForm {
    margin-bottom: 2rem;
  }
  
  .formGroup {
    margin-bottom: 1.5rem;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: var(--text-color);
  }
  
  .formGroup input {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid var(--input-border);
    border-radius: 0.5rem;
    font-size: 1rem;
    transition: border-color 0.2s ease-in-out;
  }
  
  .formGroup input:focus {
    outline: none;
    border-color: var(--input-focus);
    box-shadow: 0 0 0 3px rgba(165, 180, 252, 0.5);
  }
  
  .button {
    display: inline-block;
    background-color: var(--primary-color);
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 100%;
  }
  
  .button:hover {
    background-color: #4f46e5;
    transform: translateY(-1px);
  }
  
  .deleteButton {
    background-color: var(--error-color);
  }
  
  .deleteButton:hover {
    background-color: #dc2626;
  }
  
  .message {
    margin-top: 1.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    text-align: center;
    font-weight: 500;
  }
  
  .success {
    background-color: #d1fae5;
    color: #065f46;
  }
  
  .error {
    background-color: #fee2e2;
    color: #991b1b;
  }
  
  .confirmationBox {
    text-align: center;
    padding: 1.5rem;
    background-color: #fffbeb;
    border: 1px solid #fef3c7;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .confirmationBox p {
    margin-bottom: 1rem;
    color: #92400e;
    font-weight: 500;
  }
  
  #recaptcha-container {
    margin-top: 1.5rem;
  }
  
  @media (max-width: 640px) {
    .content {
      padding: 1.5rem;
    }
    
    .header h1 {
      font-size: 2rem;
    }
  }