:root {
    --primary-color: #6c63ff;
    --secondary-color: #4caf50;
    --text-color: #333;
    --bg-color: #f5f7fa;
    --card-bg: #ffffff;
}

.container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--bg-color);
}

.header {
    text-align: center;
    margin-bottom: 30px;
}

.logo {
    width: 120px;
    height: 40px;
    margin-bottom: 20px;
}

.header h1 {
    font-size: 2em;
    color: var(--primary-color);
    margin-bottom: 20px;
}

.loginForm {
    background-color: var(--card-bg);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.formGroup {
    margin-bottom: 20px;
}

.formGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.formGroup input[type="tel"],
.formGroup input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}

.button {
    display: inline-block;
    background-color: var(--primary-color);
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: var(--secondary-color);
}

.message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

.success {
    background-color: #d4edda;
    color: #155724;
}

.error {
    background-color: #f8d7da;
    color: #721c24;
}